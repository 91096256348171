import React, { useState } from 'react';
import { Component } from 'react';
import {useNavigate} from 'react-router-dom';

class Kontakt extends Component {
  
  render() { 
      return  <div className='mainContentWrapper'>
                <div className="contentContainer">
                    <h4>Kontakt</h4>
                </div>
              </div>
              
  }
}

export default Kontakt;




