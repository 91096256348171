import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import '../warenkorb.css'

const Warenkorb = () => {

        const { sqlID, callerID, sessionID} = useParams();
        const [warenkorb, setWarenkorb] = useState([]);



        const mapWarenkorbData = (data) => {
            
            return data.records.map(wk => ({
                kopf_id: wk.kopf_id,
                knr: wk.knr,
                wk_id: wk.wk_id,
                Bezeichnung: wk.Bezeichnung,
                datum: wk.datum,
                preis: wk.preis,
                titel: wk.titel,
                menge: wk.menge,
                bestellnummer: wk.bestellnummer,
                bestellzeichen: wk.bestellzeichen,
                rabatt: wk.rabatt,
                meldenummer: wk.meldenummer
            }));
        };
        
        const preisAnzeige = (preis) => {
            return preis.toFixed(2).replace('.',',');
        }

        const lieferbarAnzeige = (meldenummer) => {
            if (meldenummer === '000') {
                return 'lieferbar';
            }
        }
    
        useEffect (() => {
            const fetchWarenkorb = async () => {
                try {
                   //   const apiUrl = `/wk-anzeige/${sqlID}/${callerID}/${sessionID}/`
                    
                  const apiUrl = `/wk-anzeige/1001070/0107534/TEST-737-BDAF-4903-92BA-46824BA34D2D/`
    
                   
                    const response = await fetch(apiUrl);
                    if (!response.ok) {
                        throw new Error ('failed to fetch data');
                    }
    
                    const data = await response.json();
    
                    const warenkorbMapped = mapWarenkorbData(data);
    
                    setWarenkorb(warenkorbMapped);
    
    
    
                } catch (error) {
                    console.error('Error fetching data', error);
                }
            };
    
            fetchWarenkorb();
        }, [sqlID, callerID, sessionID]);








    return (
        
            <div className="mainContentWrapper">

                <div className="contentContainer">
                    

                    

                            <h4>Warenkorb </h4> 
                        
                        
                            <>

                                    <div className="wkAutoOrder">
                                        <p>Automatisches Versenden des Warenkorbs</p>
                                        <div className="wkAutoOrderNotSelected">
                                            Achtung!
                                            Möchten Sie das <b>automatische Absenden des Warenkorbs</b> aktivieren? 
                                            <br />
                                            Hinweis: Sie können die getroffene Entscheidung jederzeit wieder Ändern.
                                        </div>
                                        <div className="wkAutoOrderSelectedYes">
                                            Das automatische Absenden des Warenkorbs ist <b>aktiviert</b>.
                                            <br />
                                            ---Schieberegler zum Ändern---
                                        </div>
                                        <div className="wkAutoOrderSelectedNo">
                                            Das automatische Absenden des Warenkorbs ist <b>deaktiviert</b>.
                                            <br />
                                            ---Schieberegler zum Ändern---
                                            ---Bestellbutton---
                                        </div>
                                    </div>

                                    <hr className='hrUmb'/>

                                    <div className="wkFlex">

                                        {warenkorb.map( wk => ( 
                                            <div class="wkGrid wkGridResultsRow" key={wk.wk_id}>
                                                    <div class="wkBestellnummer"> 
                                                        <i>{wk.bestellnummer} </i>
                                                        <br />
                                                        {wk.titel}
                                                    </div>
                                                    
                                                    <div class="wkBestellzeichen"> 
                                                        {/*<input name="bestellzeichen" default="{wk.bestellzeichen}" id="bestellzeichen" type="textarea"/> */} 
                                                        <br />
                                                        {wk.bestellzeichen}  (BZ)
                                                        
                                                    </div>
                                                    <div class="wkPreisAnzeige"> 
                                                        Preis {preisAnzeige(wk.preis)} € 
                                                        <br /> 
                                                        Rabatt: {wk.rabatt}%
                                                        <br /> 
                                                        Anzahl {wk.menge}
                                                    </div>
                                                    <div class="wkButtons">
                                                            <button className="umbButtonIconDelete"> 
                                                                    <span className="iconDeletetHover"></span>
                                                                </button>  
                                                                <br/>
                                                                <div className='styledDiv'>
                                                                    <div className={lieferbarAnzeige(wk.meldenummer)}>
                                                                    {lieferbarAnzeige(wk.meldenummer)}
                                                                    </div>
                                                                    <span class="lieferbarkeitDot"></span>

                                                                </div> 
                                                    </div>
                                            </div>

                                        ))}    
                                    </div>  

                                    <div>

                                        {warenkorb.map( wk => ( 
                                            <div class="wkGrid wkGridResultsRow" key={wk.wk_id}>
                                                    <div class="wkBestellnummer"> 
                                                        <i>{wk.bestellnummer} </i>
                                                        <br />
                                                        {wk.titel}
                                                    </div>
                                                    
                                                    <div class="wkBestellzeichen"> 
                                                        {/*<input name="bestellzeichen" default="{wk.bestellzeichen}" id="bestellzeichen" type="textarea"/> */} 
                                                        <br />
                                                        {wk.bestellzeichen}  (BZ)
                                                        
                                                    </div>
                                                    <div class="wkPreisAnzeige"> 
                                                        Preis {preisAnzeige(wk.preis)} € 
                                                        <br /> 
                                                        Rabatt: {wk.rabatt}%
                                                        <br /> 
                                                        Anzahl {wk.menge}
                                                    </div>
                                                    <div class="wkButtons">
                                                            <button className="umbButtonIconDelete"> 
                                                                    <span className="iconDeletetHover"></span>
                                                                </button>  
                                                                <br/>
                                                                <div className='styledDiv'>
                                                                    <div className={lieferbarAnzeige(wk.meldenummer)}>
                                                                    {lieferbarAnzeige(wk.meldenummer)}
                                                                    </div>
                                                                    <span class="lieferbarkeitDot"></span>

                                                                </div> 
                                                    </div>
                                            </div>

                                        ))}    
                                    </div>    
                            </>
                        
                                

                    </div>
            </div>
                




    )
}
 
export default Warenkorb;