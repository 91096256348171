import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import { mapData } from '../datamapper';
import '../carousel.css';

const CardCarousel = () => {


    const [nexusSelect, setNexusSelect] = useState([]);
    const [itemsToShow, setItemsToShow] = useState(5);
    const [currentIndex, setCurrentIndex] = useState(0);
   
    const preisAnzeige = (preis) => {
        return preis.toFixed(2).replace('.',',');
    }

    useEffect(() => {
        const fetchNexusSelect = async () => {
            try {
                const apiUrl = `/search?stichwort=&count=50&offset=0&sort=rang&sort_order=desc&produktgruppe=AL`;
                const response = await fetch(apiUrl);
                if (!response.ok) {
                    throw new Error('Failed to fetch search results');
                }
                const data = await response.json();
                const mappedData = mapData(data);
                setNexusSelect(mappedData); // Assuming the response is an array of search results
            } catch (error) {
                console.error('Error fetching search results:', error);
            }
        };

        fetchNexusSelect();
    }, []); 


    

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth <= 576) {
            setItemsToShow(1);
          } else if (window.innerWidth <= 768) {
            setItemsToShow(2);
          } else if (window.innerWidth <= 992) {
            setItemsToShow(3);
          } else if (window.innerWidth <= 1200) {
            setItemsToShow(4);
          } else {
            setItemsToShow(5);
          }
        };
    
        window.addEventListener('resize', handleResize);
        handleResize();
    
        return () => window.removeEventListener('resize', handleResize);
      }, []);
    



    const nextSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % nexusSelect.length);
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + nexusSelect.length) % nexusSelect.length);
    };

    useEffect(() => {
        const interval = setInterval(nextSlide, 3500);
        return () => clearInterval(interval);
    }, [nexusSelect.length, itemsToShow]);

    return (

            <div className="contentContainer">
                    <div className="landingPageText">
                        <br />
                        <h3>Zukünftig individueller Begrüßungstext</h3>
                        <br />
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, 
                        sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. 
                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. 
                        At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.
                    
                    </div>

                    <br />
                    <hr className="hrUmb" />
                    <br />
                    <div className="carouselContainer">
                        <button onClick={prevSlide} className="carouselButton prevButton" />
                    <div className="carouselWrapper">
                            <div
                                className="carouselContent"
                                style={{ transform: `translateX(-${currentIndex * (100 / itemsToShow)}%)` }}
                                >
                                {nexusSelect.map((item) => (
                                            <div className="carouselItem" key={item.id}>
                                                <div className="card">
                                                        <div className="cardImgContainer">
                                                            
                                                            {Array.isArray(item.cover) && item.cover.length > 0 ? (
                                                                // Display the first entry in the cover array
                                                                <img className="card-img-top image-height" src={item.cover[0]} alt="" />
                                                            ) : (
                                                                // Display a placeholder image if cover is not an array or is empty
                                                                <img className="card-img-top image-height" src="noImage.jpg" alt="" />
                                                            )}

                                                        </div>    
                                                        <div className="card-body">
                                                            <div className="cardHeadlineContainer">
                                                                <b className="card-title">{item.titel}</b>
                                                                <br />
                                                                <p className="card-text">{item.autor}</p>
                                                            </div>
                                                           
                                                            <div className="cardHeadlineVerlag">    
                                                                <p className="card-text">{item.verlag}</p>
                                                            </div>
                                                            
                                                            <div className="cardDetailContainer"> 
                                                                <div className="cardPreisAnzeige">
                                                                     <b>{preisAnzeige(item.preis)} €</b>
                                                                     <br/>
                                                                     {item.meldetext}
                                                                </div>   
                                                                <div className="cardCartButton">  
                                                                    <button className="umbButtonIconCart" disabled={item.meldetext==='vergriffen'}> 
                                                                        <span className="iconCartHover"></span>
                                                                    </button>
                                                                    
                                                                </div>    
                                                            </div>     
                                                        </div>
                                                </div>        
                                            </div>
                                        ))} 
                            </div>
                    </div>
                    <button onClick={nextSlide} className="carouselButton nextButton" />
                    </div>
        </div>
      );

};

export default CardCarousel;