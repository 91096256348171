import React, {useState} from "react";
import '../overlay.css'

const CoverOverlay = ({ covers, onClose}) => {
    const [selectedCover, setSelectedCover] = useState(covers[0]);

    const getOriginalCoverUrl = (coverUrl) => {
        // Insert "_original" into the URL string after "/cover"
        return coverUrl.replace('/bildzentrale/', '/bildzentrale_original/');
    };


return (
    <div className="overlayForCovers">

        <button className="closeButton" onClick={onClose}>Fenster schließen</button>
        <div className="mainImage">
                <img src={getOriginalCoverUrl(selectedCover)} alt="Selected Cover" onClick={onClose} />
        </div>
         {covers.length > 1 && (
             <div className="overlayCarousel">
             {covers.map((cover, index) => (
                 <img 
                     key={index}
                     src={getOriginalCoverUrl(cover)} // Call the function with `cover` to get the modified URL
                     onClick={() => setSelectedCover(cover)}
                     className={`thumbnail ${cover === selectedCover ? 'active' : ''}`}                   
                 />
             ))}
            </div>    

         )}

    </div>
)};

export default CoverOverlay;