export const mapData = (data) => {
    if (!data || !Array.isArray(data.records)) {
        return []; // Return an empty array or handle the error as needed
    }

    return data.records.map(record => ({
        id: record.id,
        titel: record.titel,
        untertitel: record.untertitel,
        cover: record.cover,
        isbn: record.ean,
        ean: record.ean,
        preis: record.euro_d,
        verlag: record.verlag,
        autor: record.autor,
        herausgeber: record.herausgeber,
        erscheinungsdatum: record.datum_erscheinungstermin_yyyymmdd,
        formatangaben: record.formatangaben,
        format: record.format,
        produktform: record.produktform,
        themencode: record.themen,
        themen: record.themen_bezeichnungen,
        themenbez: record.themen_bezeichnungen,
        sprache: record.produktsprache,
        illustration: record.illustrator,
        umbreitnummer: record.titelnummer_umbreitnummer,
        rang: record.rang,
        produktgruppe: record.produktgruppe,
        //thumbnail:  modifyUrl(record.cover)
    }));
};



