// import wovon aus welcher Library oder Ordner
import React from "react"

//import ( StrictMode )

import * as ReactDomClient from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css" //Rechtsklick auf Dateinamen copy relative path
import "./index.css";


import App from "./PresseWebShop";


const root = ReactDomClient.createRoot(document.getElementById("root"));

root.render(<App></App>);


