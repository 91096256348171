import React, { Component } from 'react';
import {Link} from 'react-router-dom';



class Header extends Component {
    state = {  } 
    render() { 
        const { loggedIn } = this.props;

        return (
            <div className="contentContainer">
                        <div className="headerContainer">
                            <div className="headerLeft">
                                <div className='logo'>
                                    <Link to="/">
                                        <img src='/assets/img/umbreit-logo.svg' alt=''/>
                                    </Link>
                                </div>
                                <div className='firmenName'>
                                    Demo-Pressewebshop<br/>
                                    Version dev.202412
                                </div>
                            </div>
                            <div className="headerRight">
                                <div className='firmenDaten'>
                                    {!loggedIn ?  (
                                                <div className="umBorderLeft">
                                                    <div className="headerHotline">
                                                        <b>Service-Hotline:&nbsp;</b> 
                                                        555-SHOE
                                                    </div>
                                                    <div className="headerServiceZeiten">
                                                        <b>Service-Zeiten:&nbsp;</b>  23:59 - 00:01 Uhr
                                                    </div>
                                                    <div className="headerOffenZeiten">
                                                        <b>Öffnungszeiten:&nbsp;</b> 0:10 - 23:50 Uhr
                                                    </div>
                                                </div> 
                                                ) : ( 
                                                    <div className="umBorderLeft">
                                                        angemeldet als: <br /><br />
                                                        <b>Schuhnachrichten Vertrieb</b> <br />
                                                        A House in the Middle of the Road 35 <br />
                                                        Chicago, Illinois
                                                        
                                                    </div>
                                                )
                                    }
                             
                                </div>
                                
                            </div>

                    

                        </div>
            </div>            
        );
    }
}
 
export default Header;