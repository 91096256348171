import React, { useState } from 'react';
import { Component } from 'react';
import {useNavigate} from 'react-router-dom';

class Datenschutz extends Component {
  
  render() { 
      return  <div className='mainContentWrapper'>
                <div className="contentContainer">
                    <h4>Datenschutz</h4>
                </div>
             </div>
              
  }
}

export default Datenschutz;




