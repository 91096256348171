import React, { Component } from 'react';
import {Link} from 'react-router-dom';

class Footer extends Component {
    state = {  } 
    render() { 
        return  <div className="footer">
                    <hr className='hrUmb'/>
                    <div className="footerElementWrapper">
                        <div className='footerElement'>
                           <Link className="footerLink" to="/impressum">Impressum</Link> 
                        </div>    
                        <div className='footerElement'>
                            <Link className="footerLink" to="/kontakt">Kontakt</Link> 
                        </div >  
                        <div className='footerElement'>
                            <Link className="footerLink" to="/agb">AGB</Link> 
                        </div>  
                        <div className='footerElement'>
                            <Link className="footerLink" to="/datenschutz">Datenschutz</Link> 
                        </div>  
                    </div>
                    <div className="poweredBy">
                        <img src='/assets/img/powered-by-umbreit.svg' alt=''/> 
                    </div>
                </div>
                
    }
}
 








export default Footer;