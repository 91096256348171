import React from 'react';

const SortButton = ({ field, currentSort, onClick, text, style}) => {
    
    const isActive = currentSort.field === field;
    const sortOrder = isActive ? currentSort.order : 'none';
    const arrow = sortOrder === 'asc' ? '↑' : sortOrder === 'desc' ? '↓' : '↕';


    return (

        <button
            onClick={() => onClick(field)}
            style={style}
            className={isActive ? 'active-sort-button' : 'sort-button'}
        >
            {text} <span className="sort-arrow">{arrow}</span>
        </button>




    );
};

export default SortButton;