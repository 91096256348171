import React, { useState, useEffect } from 'react';
import { mapData } from '../datamapper';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
 
import CoverOverlay from './cover_overlay';

import '../productdetails.css'







const DetailPage = () => {

    const { ean } = useParams();
    const [product, setProduct] = useState(null);
    const [texte, setText] = useState(null);
    const { sqlID, callerID, sessionID} = useParams();
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const [isErweitert, setIsErweitert] = useState(false);
    const [autorIsVisible, setAutorIsVisible] = useState(false);
    const [autorIsErweitert, setAutorIsErweitert] = useState(false);
    const [biblioIsErweitert, setBiblioIsErweitert] = useState(false);
    const [biblioIsVisible, setBiblioIsVisible] = useState(false);
   
    const [currentIndex, setCurrentIndex] = useState(0);
    

    const [coversToShow, setCoversToShow] = useState(3);

    const [showOverlay, setShowOverlay] = useState(false);

    const openOverlay = () => setShowOverlay(true);
    const closeOverlay = () => setShowOverlay(false);
    
    const mapTexte = (data) => {
        // Ensure we have the records array
        if (data.records && data.records.length > 0) {
            return data.records.map((texte) => ({
                textisbn: texte.isbn,
                autoreninfo: texte.a,     // Author info
                zusatztext: texte.z,      // Zusatztext
                leseprobe: texte.l,       // Leseprobe
                inhalt: texte.i,          // Inhalt
                kurztext: texte.k,        // Kurztext
                schlagzeile: texte.s      // Schlagzeile
            }));
        } else {
            // Handle case where records are empty or missing
            console.error('No records found in the response');
            return [];
        }
    };

    const preisAnzeige = (preis) => {
        return preis.toFixed(2).replace('.',',');
    }

    const formatDate = (erscheinungsdatum) => {
        const year = erscheinungsdatum.substring(0, 4);
        const month = erscheinungsdatum.substring(4, 6);
        const day = erscheinungsdatum.substring(6, 8);
        return `${day}.${month}.${year}`;
    };

    const showDetailAngaben = () => {
        const {format} = useParams();

        if (format === '') {
            setIsVisible(!isVisible);
        }
    }


    const fetchProduct = async () => {
        try {
            console.log('fetching product with ean:', ean);
            const response = await fetch( `/search?ean=${ean}`);
            if (!response.ok) {
                throw new Error('Failed to fetch product details');
            }
            const data = await response.json();
            const mappedData = mapData(data); // Map data using your data mapper
            console.log('Produktdetails: ', mappedData);

            setProduct(mappedData);
        } catch (error) {
            console.error('Error fetching product details:', error);
        }
    };

        // Sample function to generate the "_original" URL
    const getOriginalCoverUrl = (coverUrl) => {
        // Insert "_original" into the URL string after "/cover"
        return coverUrl.replace('/bildzentrale/', '/bildzentrale_original/');
    };

   

    const fetchThemen = async (thema) => {
        const response = await fetch(`/search-thema/TEST-737-BDAF-4903-92BA-46824BA34D2D/${thema}/`)
    }


    const fetchText = async (ean) => {
        try {
            console.log('Texte holen für EAN:', ean);
            const response = await fetch(`/get-texte/1000136/0107534/TEST-737-BDAF-4903-92BA-46824BA34D2D/${ean}/`);
            const data = await response.json();
            console.log('Received Data:', data);  // Inspect the received data
            const mappedTexte = mapTexte(data);   // Map the data
            setText(mappedTexte);                 // Update state with mapped data
            console.log('Texte geholt', mappedTexte);
        } catch (error) {
            console.error('Error fetching texts:', error);
        }
    };

    useEffect(() => {
        if (ean) {
            console.log('Fetching product for EAN:', ean);
            fetchProduct();
            fetchText(ean);
        }
    }, [ean])

    
    useEffect(() => {
      
        if (product && product.ean) {
            console.log('Fetching Texts for EAN:', product.ean);
            console.log('ISBN:', texte.isbn)
            
            fetchText(product.ean);
        } else {
            console.log('product or EAN not available yet');
        }
    }, [product]);



    
  

    if (!product) {
        return <div className="loadingGif"><img src="/assets/img/icons/loadingBook.gif" /></div>;
    }

    console.log('Current Index:', currentIndex);
    console.log('Product:', product);


    const zusatzCover = Array.isArray(product.cover) && product.cover.length > 1 ? product.cover.slice(1) : [];
    const themenListe = Array.isArray(product.themenbez) && product.themenbez.length > 1 ? product.themenbez.slice(1): [];

    console.log('Liste Cover:', zusatzCover);
    console.log('Product cover array:', product.cover);

    const nextCover = (covers, setCurrentIndex) => {
        if (covers.length > 1) {
            setCurrentIndex((prevIndex) => {
                const newIndex = (prevIndex + 1) % covers.length;
                console.log('Next Slide:', newIndex);
                return newIndex;
            });
        }
    };
    
    const prevCover = (covers, setCurrentIndex) => {
        if (covers.length > 1) {
            setCurrentIndex((prevIndex) => {
                const newIndex = (prevIndex - 1 + covers.length) % covers.length;
                console.log('Previous Slide:', newIndex);
                return newIndex;
            });
        }
    };


    const toggleTextBeschreibungMobile = () => {
        setIsVisible(!isVisible);
        setIsErweitert(!isErweitert);
    }

    const toggleTextAutorMobile = () => {
        setAutorIsVisible(!autorIsVisible);
        setAutorIsErweitert(!autorIsErweitert);
    }

    const toggleBibliopraphieMobile = () => {
        setBiblioIsVisible(!biblioIsVisible);
        setBiblioIsErweitert(!biblioIsErweitert)
    }


    console.log(product); 

    return (    <div className="mainContentWrapper">
                    <div className="contentContainer">
                    {product ? (
                            product.map((product) => (            
                                        <div> 
                                                                                                                    
                                            <div class="parentDetailViewGrid">

                                                <div className="gridCover umBorderRight">
                                                <div className="containerBibCover">     
                                                    <div className="detailCoverContainer">
                                                        {Array.isArray(product.cover) && product.cover.length > 0 ? (
                                                           
                                                            <a onClick={openOverlay}>
                                                                    <img className="card-img-top image-height" src={product.cover[0]} alt="" />
                                                              </a>
                                                        ) : (
                                                            
                                                            <img className="card-img-top image-height" src="/assets/img/icons/noImage.png" alt="" />
                                                        )}
                                                    </div>

                                                    {showOverlay && (
                                                        <CoverOverlay
                                                            covers={product.cover}
                                                            onClose={closeOverlay}
                                                        />
                                                    )}

                                                    {Array.isArray(product.cover) && product.cover.length > 1 && (
                                                    <div className="coverCarouselContainer">
                                                      
                                                                <div className="additionalCoverCount">
                                                                    {`${product.cover.length - 1} weitere Abbildung${product.cover.length - 1 === 1 ? "" : "en"}`}
                                                                </div>
                                                        
                                                                <button onClick={() => prevCover(product.cover.slice(1), setCurrentIndex)} className="carouselButton prevButton" />
                                                        
                                                   
                                                                <div className="coverCarouselWrapper">  
                                                                        <div className="coverCarouselContent"
                                                                            style={{ transform: `translateX(-${currentIndex * 100 / product.cover.slice(1).length}%)` }}
                                                                        >
                                                                                
                                                                                    <>
                                                                                        
                                                                                        {product.cover.slice(1).map((cover, index) => (
                                                                                            <div className="coverCarouselItem" key={index}>  
                                                                                                <a onClick={openOverlay}>
                                                                                                    <div className="additionalCoverPreview">
                                                                                                        <img className="detailCover" src={cover} alt="" />
                                                                                                    </div>
                                                                                                </a>
                                                                                            </div>
                                                                                        ))}
                                                                                    </>
                                                                                       
                                                                        </div>

                                                                </div>

                                                           
                                                                <button onClick={() => nextCover(product.cover.slice(1), setCurrentIndex)} className="carouselButton nextButton" />
                                                             
                                                    </div>
                                                        
                                                    )}                                                        

                                                        

                                                            
                                                </div>

                                                </div>



                                                <div className="gridDetails"> 
                                                    
                                                        <div className="detailBaseInfo">
                                                             <h3 className="detailTitel"><b>{product.titel}</b></h3>
                                                            <br/>          
                                                            <h5>{product.untertitel}</h5>
                                                           
                                                            <br/>
                                                            <div>{product.autor}</div>
                                                            <div>{product.verlag}</div>
                                                         

                                                            {product.produktgruppe && (
                                                               <div> Produktgruppe: {product.produktgruppe} </div>    
                                                            )}
                                                            
                                                            erschienen am {formatDate(product.erscheinungsdatum)}
                                                          
                                                            {product.herausgeber  && ( 
                                                                <div className="herausgeberDiv">Herausgeber: {product.herausgeber}</div>
                                                            )}
                                                            <br />
                                                            {product.illustration && (
                                                                <div className="illustrationDiv">Illustrationen: {product.illustration}</div>
                                                            )}
                                                           
                                                            {product.themenbez && (
                                                             <div>Themen: {product.themenbez ? product.themenbez.join(' ') : ''}</div>
                                                            )}

                                                            <br/>
                                                        <div className="detailButtonAreaMobile"> 
                                                    
                                                            <div className="detailMeldePreis">
                                                                <div className="detailPreisStyle">
                                                                    {preisAnzeige(product.preis)} €
                                                                </div>    
                                                                <div className={product.meldetext}>
                                                                    {product.meldetext}
                                                                </div>    
                                                                <br />
                                                                
                                                            </div>
                                                            <div className="buttonContainerMobile">    
                                                                <button className="umbButtonIconList"> 
                                                                    <span className="iconListHover"></span>
                                                                </button> 
                                                                <button className="umbButtonIconCart" disabled={product.meldetext==='vergriffen'}> 
                                                                    <span className="iconCartHover"></span>
                                                                </button>

                                                                <button className="umbButtonIconBackToSearch" onClick={() => navigate('/suche')}> 
                                                                    <span className="iconSearchHover"></span>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="bibliographieContainerMobile"> 

                                                        <div className="showThisOnlyMobile">
                                                                <Link onClick={toggleBibliopraphieMobile}>
                                                                    <div className="detailTextHeadlines umBorderTop umBorderLeft">
                                                                        <h4 className="detailH">Bibliographie</h4>
                                                                        <div className="iconOnlyMobile">
                                                                            {biblioIsErweitert ? (                
                                                                                <>       
                                                                                    <img src="/assets/img/icons/minus_32_umb.png"/>
                                                                                </> 
                                                                                ) : (
                                                                                <>                
                                                                                    <img src="/assets/img/icons/plus_32_umb.png"/>
                                                                                </>     

                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>  
                                                                </Link>
                                                        </div>
                                                        <div className={`biblioBgraphieMobile umBorderLeft ${biblioIsVisible  ? 'visible' : 'hidden'}`}>
                                                                {product.artikelnummer && (    
                                                                            <div> 
                                                                                Artikelnummer: {product.artikelnummer} 
                                                                            </div>
                                                                    )}   
                                                                    {product.isbn && (      
                                                                            <div>
                                                                                ISBN/EAN: {product.isbn}
                                                                            </div>
                                                                    )} 
                                                                    {product.sprache && ( 
                                                                            <div>
                                                                                Sprache: {product.sprache}
                                                                            </div>
                                                                    )} 
                                                                    {product.formatangaben && ( 
                                                                            <div>
                                                                                Seiten: {product.formatangaben}
                                                                            </div>
                                                                    )}   
                                                                    {product.format && (
                                                                            <div className="formatDiv">
                                                                                Format: {product.format}
                                                                            </div>
                                                                    )}    

                                                                    {product.einband && ( 
                                                                            <div>
                                                                                Einband: {product.produktform}
                                                                            </div>
                                                                    )}  
                                                            </div>        
                                                        </div>

                                                        {texte && texte.length > 0 ? (
                                                            texte.map((texte, index) => (
                                                            
                                                                <div key={index}>

                                                                
                                                                    
                                                                    {texte.inhalt && 
                                                                        <div>
                                                                            <h4>Inhalt</h4>  
                                                                            {texte.inhalt}
                                                                        </div>
                                                                    }
                                                                
                                                                
                                                                    {texte.zusatztext && 
                                                                        <div>
                                                                            <div className="showThisOnlyMobile">
                                                                                <Link onClick={toggleTextBeschreibungMobile}>
                                                                                    <div className="detailTextHeadlines umBorderTop umBorderLeft">
                                                                                        <h4 className="detailH">Beschreibung</h4>
                                                                                        <div className="iconOnlyMobile">
                                                                                            {isErweitert ? (                
                                                                                                <>       
                                                                                                    <img src="/assets/img/icons/minus_32_umb.png"/>
                                                                                                </> 
                                                                                                ) : (
                                                                                                <>                
                                                                                                    <img src="/assets/img/icons/plus_32_umb.png"/>
                                                                                                </>     

                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>  
                                                                                </Link>
                                                                            </div>

                                                                            <div className={`textBeschreibungMobile umBorderLeft ${isVisible  ? 'visible' : 'hidden'}`}>
                                                                                {texte.zusatztext}
                                                                            </div>  
                                                                            
                                                                            <div className="zusatzTextDesk">
                                                                                <h4>Beschreibung</h4>
                                                                                {texte.zusatztext}
                                                                            </div> 
                                                                        </div>
                                                                    }  

                                                                    
                                                                {texte.autoreninfo &&
                                                                        <div>
                                                                                <div className="showThisOnlyMobile">
                                                                                <Link onClick={toggleTextAutorMobile}>
                                                                                    <div className="detailTextHeadlines">
                                                                                        <h4 className="detailH">Autorenportrait</h4>
                                                                                        <div className="iconOnlyMobile">
                                                                                            {autorIsErweitert ? (                
                                                                                                <>       
                                                                                                    <img src="/assets/img/icons/minus_32_umb.png"/>
                                                                                                </> 
                                                                                                ) : (
                                                                                                <>                
                                                                                                    <img src="/assets/img/icons/plus_32_umb.png"/>
                                                                                                </>     

                                                                                                )
                                                                                            }
                                                                                        </div>
                                                                                    </div>  
                                                                                </Link>
                                                                            </div>

                                                                            <div className={`textAutorenInfoMobile ${autorIsVisible  ? 'visible' : 'hidden'}`}>
                                                                                {texte.autoreninfo}
                                                                            </div>  
                                                                        </div>
                                                                    
                                                                    }

                                                                
                                                                </div>
                                                                /* 
                                                                    {texte.kurztext && <div>Kurztext: {texte.kurztext}</div>}
                                                                    {texte.schlagzeile && <div>Schlagzeile: {texte.schlagzeile}</div>} */
                                                            ))
                                                        ) : (
                                                            <p></p>
                                                        )
                                                    }
                                                                
                                                        
                                                        </div>    
                                                   
                                                    
                                                        <div className="detailButtonArea">
                                                        <div className="detailBibliographie zusatzTextDesk umBorderLeft">
                                                            {product.artikelnummer && (    
                                                                    <div> 
                                                                        Artikelnummer: {product.artikelnummer} 
                                                                    </div>
                                                            )}   
                                                            {product.isbn && (      
                                                                    <div>
                                                                        ISBN/EAN: {product.isbn}
                                                                    </div>
                                                            )} 
                                                             {product.sprache && ( 
                                                                    <div>
                                                                        Sprache: {product.sprache}
                                                                    </div>
                                                            )} 
                                                            {product.formatangaben && ( 
                                                                    <div>
                                                                        Seiten: {product.formatangaben}
                                                                    </div>
                                                            )}   
                                                            {product.format && (
                                                                    <div className="formatDiv">
                                                                        Format: {product.format}
                                                                    </div>
                                                            )}    

                                                            {product.einband && ( 
                                                                    <div>
                                                                        Einband: {product.produktform}
                                                                    </div>
                                                            )}         
                                                        </div> 
                                                            <div className="detailMeldePreis">
                                                                <div className="detailPreisStyle">
                                                                    {preisAnzeige(product.preis)} €
                                                                </div>    
                                                                <div className={product.meldetext}>
                                                                    {product.meldetext}
                                                                </div>    
                                                                <br />
                                                                
                                                            </div>
                                                            <div className="">    
                                                                <button className="umbButtonIconList"> 
                                                                    <span className="iconListHover"></span>
                                                                </button> 
                                                                <button className="umbButtonIconCart" disabled={product.meldetext==='vergriffen'}> 
                                                                    <span className="iconCartHover"></span>
                                                                </button>
                                                                <button className="umbButtonIconBackToSearch" onClick={() => navigate('/suche')}> 
                                                                    <span className="iconSearchHover"></span>
                                                                </button>
                                                            </div>
                                                              
                                                        </div>
                                                    
                                                </div>
                                                
                                        

                                             
                                                <div className="gridText"> 

                                          
                                                    
                                                      
                                                    {texte && texte.length > 0 ? (
                                                        texte.map((texte, index) => (
                                                        
                                                            <div key={index}>

                                                               
                                                           

                                                                {texte.autoreninfo && 
                                                                    <div>
                                                                         
                                                                        
                                                                        <div className="zusatzTextDesk">
                                                                            <h4>Autorenportrait</h4>
                                                                            {texte.autoreninfo}
                                                                        </div> 
                                                                    </div>
                                                                }
                                                            </div>
                                                            /* 
                                                                {texte.kurztext && <div>Kurztext: {texte.kurztext}</div>}
                                                                {texte.schlagzeile && <div>Schlagzeile: {texte.schlagzeile}</div>} */
                                                        ))
                                                        ) : (
                                                        <p></p>
                                                        )
                                                    }
                                                                
                                                    
                                                     <br />
                                                     <br />
                                                     
                                                </div>
                                               
                                            </div>          
  
                                     </div>    
                                    ))   
                                ) : (
                                    <p></p>
                                )}

                                   
                    </div>        
                </div>
    );
  };
  
  export default DetailPage;


